import React from "react";
import { graphql } from "gatsby";
import { Main } from "../components/main";
import {
  JumbotronH1,
  Jumbotron,
  JumbotronH2,
  JumbotronContent,
} from "../components/jumbotron";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import SEO from "../components/seo";
import Schema from "../components/schema";

const IndexPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter, html },
  } = data;
  return (
    <>
      <SEO
        lang="fr"
        description={frontmatter.description}
        title={frontmatter.title}
        meta={[{ name: "keywords", content: frontmatter.keywords }]}
      />
      <Navbar />
      <div className="container">
        <Jumbotron
          image={
            (frontmatter.banner &&
              frontmatter.banner.childImageSharp.fluid.src) ||
            ""
          }
        >
          <JumbotronContent backgroundColor="transparent">
            <div>
              <JumbotronH1>
                {frontmatter.title}
                <JumbotronH2>{frontmatter.subtitle}</JumbotronH2>
              </JumbotronH1>
            </div>
          </JumbotronContent>
        </Jumbotron>
        <Main withMap>
          <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
        </Main>
      </div>
      <Footer />
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        subtitle
        category
        keywords
        description
        banner {
          childImageSharp {
            fluid(maxWidth: 1100, maxHeight: 400) {
              src
            }
          }
        }
      }
    }
  }
`;
